<div class="bottom-bg"></div>

<!-- footer area start -->
<footer class="footer-area footer-style-2">
  <div class="footer-top padding-top-40 padding-bottom-0">
    <div class="container">
      <div class="row">
        <div class="col-lg-2 col-md-2">
          <div class="footer-widget widget">
            <div class="about_us_widget">
              <a href="index.html" class="footer-logo">
                <img src="assets/img/footer-logo.png" alt="footer logo">
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-7 col-md-6">
          <div class="footer-widget widget widget_nav_menu">
            <ul>
              <li><a href="#">Home</a></li>
              <li><a href="#">About</a></li>
              <li><a href="#">Blog</a></li>
              <li><a href="#">Contact</a></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-4">
          <div class="footer-widget widget widget_nav_menu">
            <ul>
              <li><a href="https://www.facebook.com/codingeek.net/" target="_blank"><i class="fa fa-facebook-f"></i></a></li>
              <li><a href="#" target="_blank"><i class="fa fa-twitter"></i></a></li>
              <li><a href="#" target="_blank"><i class="fa fa-instagram"></i></a></li>
              <li><a href="#" target="_blank"><i class="fa fa-youtube"></i></a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="copyright-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="copyright-area-inner">
            © TopMotero 2021 All rights reserved. Powered with <i class="fa fa-heart"></i> by <a href="https://www.softtopia.com/" target="_blank">Softtopia</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
<!-- footer area end -->
