<div class="coming-soon-area">
  <div class="content">
    <h1>WE'RE COMING SOON</h1>
    <p>Estamos en moto, rodando nuestra primera temporada, con muchas ganas de compartir!.</p>
    <div class="countdown">
      <div class="countdown__item"><h2 class="countdown__lg-text">{{days}}</h2><span class="countdown__sm-text">Dias</span></div>
      <div class="countdown__item"><h2 class="countdown__lg-text">{{hours}}</h2><span class="countdown__sm-text">Horas</span></div>
      <div class="countdown__item"><h2 class="countdown__lg-text">{{mins}}</h2><span class="countdown__sm-text">Minutos</span></div>
      <div class="countdown__item"><h2 class="countdown__lg-text">{{sec}}</h2><span class="countdown__sm-text">Segundos</span></div>
    </div>
  </div>
</div>
