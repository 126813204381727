import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';

//TODO: We should use something like this: https://github.com/mehmet-erim/ngx-slickjs

@Component({
  selector: 'app-home-banner',
  templateUrl: './home-banner.component.html',
  styleUrls: ['./home-banner.component.css']
})
export class HomeBannerComponent implements OnInit, AfterViewInit {
  @ViewChild('bannerSlider') bannerSlider;
  constructor() { }
  public bannerSections: any[] = [
    {
      backgroundImage: 'assets/img/bg/1.jpg',
      smallBackgroundImage: 'assets/img/bg/sm1.png',
      innerText: 'Preparate para el lanzamiento de Topmotero.com <br> experiencias, reviews consejos y mucho más...',
      fadeInDownText: 'Compartimos',
      fadeInUpText: 'Nuestra pasión por las motos'
    }/*,
    {
      backgroundImage: 'assets/img/bg/2.jpg',
      smallBackgroundImage: 'assets/img/bg/sm2.png',
      innerText: 'Videos de motos al estilo TopMotero.',
      fadeInDownText: 'Videos',
      fadeInUpText: 'Sobre motos'
    },
    {
      backgroundImage: 'assets/img/bg/3.jpg',
      smallBackgroundImage: 'assets/img/bg/sm3.png',
      innerText: 'Comparativas Top Moto.',
      fadeInDownText: 'Comparativas',
      fadeInUpText: 'Probando a fondo'
    },
    {
      backgroundImage: 'assets/img/bg/4.jpg',
      smallBackgroundImage: 'assets/img/bg/sm4.png',
      innerText: 'Sigue nuestros consejos para mejorar tus experiencias en moto.',
      fadeInDownText: 'Consejos',
      fadeInUpText: 'Moteros'
    },
    {
      backgroundImage: 'assets/img/bg/5.jpg',
      smallBackgroundImage: 'assets/img/bg/sm5.png',
      innerText: 'Primera temporada, Veteranos y Principiantes.',
      fadeInDownText: 'Temporada 1',
      fadeInUpText: 'Veteranos y Principiantes'
    }*/
    ];

  ngOnInit(): void {
    console.log("ngOnInit--");

  }

  ngAfterViewInit(): void {
    console.log("Super ! dooper sela que rutlla")
    console.log("ngAfterViewInit--->>");
    console.log(this.bannerSlider);
    // banner slider
    /*this.bannerSlider.nativeElement.slick({
      dots: false,
      arrows: true,
      asNavFor: '.banner-sm-slider',
      fade: true,
      autoplay: true
    });

    this.bannerSlider.nativeElement.slickAnimation();*/

  }

}
