<!-- navbar start -->
<div class="billa-navbar">
  <nav class="navbar navbar-area navbar-expand-lg nav-style-01">
    <div class="container nav-container">
      <div class="responsive-mobile-menu">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#billatrail_main_menu"
                aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
          <span class="bar1"></span>
          <span class="bar2"></span>
          <span class="bar3"></span>
        </button>
      </div>
      <div class="logo">
        <a href="index.html"> <img src="assets/img/logo.png" alt="logo"></a>
      </div>
      <div class="collapse navbar-collapse" id="billatrail_main_menu">
        <ul class="navbar-nav">
          <li class="current-menu-item">
            <a href="#">Home</a>
          </li>
          <li class="menu-item-has-children">
            <a href="#">Videos</a>
            <ul class="sub-menu">
              <li><a href="#">Temporada 1</a></li>
              <li><a href="#">Los mas vistos</a></li>
            </ul>
          </li>
          <li class="menu-item-has-children">
            <a href="#">Motos</a>
            <ul class="sub-menu">
              <li><a href="#">Reviews</a></li>
              <li><a href="#">Fichas técnicas</a></li>
            </ul>
          </li>
          <li class="menu-item-has-children">
            <a href="#">Rutas</a>
            <ul class="sub-menu">
              <li><a href="#">Como preparar tu ruta</a>
              <li><a href="#">La Transpir</a>
            </ul>
          </li>
          <li class="menu-item-has-children">
            <a href="#">Consejos</a>
            <ul class="sub-menu">
              <li><a href="#">Mejora tu frenada</a>
              <li><a href="#">Como limpiar tu moto</a>
            </ul>
          </li>
          <!-- <li><a href="contact.html">Contacto</a></li> -->
        </ul>
      </div>
      <div class="nav-right-content">
        <ul class="nav-right-menu">
          <li class="search" id="search">
            <i class="fa fa-search"></i>
          </li>
          <li>
            <div class="humberger-wrapper d-none d-lg-block">
              <div role="navigation" class="humberger-menu">
                <div id="menuToggle">
                  <input type="checkbox" />
                  <span></span>
                  <span class="second"></span>
                  <span></span>

                  <ul id="menu">
                    <li><a href="signin.html">Sign In</a></li>
                    <li><a href="signup.html">Sign Up</a></li>
                    <li><a href="contact.html">Contact Us</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</div>
<!-- navbar end -->
