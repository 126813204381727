import {Component, OnDestroy, OnInit} from '@angular/core';

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.css']
})
export class CountdownComponent implements OnInit, OnDestroy {
  private id: any;

  constructor() { }
  public days: number;
  public hours: number;
  public mins: number;
  public sec: number;
  launchDate = new Date('Apr 28, 2021 00:00:00').getTime();

  ngOnInit(): void {
      this.id = setInterval(() => {
        this.tick();
    }, 1000);
  }

  tick(): void {
    const now = new Date().getTime();
    const distance = this.launchDate - now;

    this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
    this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    this.mins = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    this.sec = Math.floor((distance % (1000 * 60)) / 1000);


    // If launch date passed
    if (distance < 0) {
      // Stop countdown
      clearInterval(this.id);
    }

  }

  ngOnDestroy(): void {
    if (this.id) {
      clearInterval(this.id);
    }
  }

}
