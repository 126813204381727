<!-- banner start -->
<div class="banner-area home-banner1">
  <div #bannerSlider class="banner-slider banner-slider1">

      <div *ngFor="let section of bannerSections" class="banner-bg" style="background-image:url({{section.backgroundImage}});">
        <div class="container">
          <div class="row h-100vh">
            <div class="col-xl-10 col-lg-10 offset-lg-2 offset-xl-1 col-md-8 offset-md-2">
              <div class="banner-inner">
               <!-- <p data-animation-in="fadeInLeft" [innerHTML]="section.innerText"></p>
                <h1 class="title1" data-animation-in="fadeInDown">{{section.fadeInDownText}}</h1>
                <h1 class="title2" data-animation-in="fadeInUp">{{section.fadeInUpText}}</h1> -->
                <p  [innerHTML]="section.innerText"></p>
                <h1 class="title1" >{{section.fadeInDownText}}</h1>
                <h2 class="title3" >{{section.fadeInUpText}}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>

  <!-- social icon -->
  <ul class="social-icon">
    <li class="icon-list">
      <a href="#" class="icon-text">
        <i class="fa fa-twitter"></i>
      </a>
    </li>
    <li class="icon-list">
      <a href="https://www.facebook.com/codingeek.net/" target="_blank" class="icon-text">
        <i class="fa fa-facebook-f"></i>
      </a>
    </li>
    <li class="icon-list">
      <a href="#" class="icon-text">
        <i class="fa fa-instagram"></i>
      </a>
    </li>
    <li class="icon-list">
      <a href="#" class="icon-text">
        <i class="fa fa-youtube"></i>
      </a>
    </li>
  </ul>

  <!-- video button -->
  <div class="video-btn">
    <a href="#"><i class="fa fa-play"></i></a>
  </div>

  <!-- scroll down -->
  <div class="scroll">
    <a href="#about-area"><div class="scroll-btn"><span class="inside-bg"></span></div></a>
    <span>Scroll to explore</span>
  </div>

 <!-- <div class="banner-bottom">
    <div class="container">
      <div class="row">
        <div class="col-xl-7 col-lg-5 d-none d-lg-block">
          <div class="controler-wrapper">
            <div class="active-controler">01</div>
            <div class="progressbar">
              <span class="home-slider-progress"></span>
              <span class="home-slider-progress-active"></span>
            </div>
            <div class="total-controler">0{{bannerSections.length}}</div>
          </div>
        </div>
        <div class="col-xl-3 offset-lg-1 col-lg-4 col-md-6 offset-md-2 col-sm-6 offset-sm-2 col-10 offset-1">
          <div class="banner-sm-slider d-flex">
            <div *ngFor="let section of bannerSections" class="slider-image1" ><img style="background: #7ea7dc" [src]="section.smallBackgroundImage" alt=""></div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <!--social-icon-->
</div>
<!-- banner end -->
