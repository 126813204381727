
<!-- preloader area start -->
<div class="preloader" id="preloader">
  <div class="preloader-inner">
    <div class="spinner">
      <div class="dot1"></div>
      <div class="dot2"></div>
    </div>
  </div>
</div>
<!-- preloader area end -->

<app-nav-bar></app-nav-bar>

<app-home-banner></app-home-banner>

<app-countdown></app-countdown>

<router-outlet></router-outlet>

<app-footer></app-footer>


<!-- back to top area start -->
<div class="back-to-top">
  <span class="back-top"><i class="fa fa-angle-up"></i></span>
</div>
<!-- back to top area end -->
